import React, { useEffect } from 'react'
import { last, nth } from 'lodash'
import { navigate } from 'gatsby'

const NotFoundPage1 = ():any => {
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const pathName = window.location.pathname
			const splitPath = pathName.split('/')
			const firstSubFolder = nth(splitPath, 1)
			if (firstSubFolder === 'draft') {
				const id = last(splitPath)
				// const newUrl = `/make/draft/${id}`
				const newUrl = `/make/draft/preview/${id}`
				navigate(newUrl, { replace: true })
			} else if (firstSubFolder === 's') {
				const idOrSlug = last(splitPath)
				const newUrl = `/i/${idOrSlug}`
				navigate(newUrl, { replace: true })
			} else {
				navigate('/404/notfound', { replace: true })
			}
		}
	}, [])
	return (
		<></>
	)
}
export default NotFoundPage1
